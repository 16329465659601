$(function () {
    "use strict";



    $(".carousel").each(function () {
        var slider = $(this);
        slider.slick({
            dots: true,
            arrows: true,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            prevArrow:
                '<button type="button" class="slick-custom-arrow slick-prev"><i class="bi bi-chevron-left"></i></button>',
            nextArrow:
                '<button type="button" class="slick-custom-arrow slick-next"><i class="bi bi-chevron-right"></i></button>',
            responsive: [

                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                    },
                },
            ],
        });

        var sLightbox = $(this);
        sLightbox.slickLightbox({
            src: "data-src",
            itemSelector: ".slick-slide > img",
            imageMaxHeight: 1,
        });
    });

    var currentIndex = 0;
    var intervalTime = 6000; // Adjust as needed

    function addCarouselCell(imageSrc) {
        var zIndex = $(".js-carousel__main ul li").length + 1;
        var directions = ["lr", "tb", "rl"];
        var directionIndex =
            (($(".js-carousel__main ul li").length % directions.length) +
                directions.length) %
            directions.length; // Calculate direction index

        var newCell = $(
            '<li class="js-carousel__cell" data-dir="' +
                directions[directionIndex] +
                '" data-caption="">\
                            <span class="js-carousel__frame">\
                                <picture id="" class="">\
                                    <source media="(min-width: 560px)" srcset="' +
                imageSrc +
                '">\
                                    <source media="(max-width: 559px)" srcset="' +
                imageSrc +
                '">\
                                    <img src="' +
                imageSrc +
                '" alt="" class="js-carousel__obj js-objectfit">\
                                </picture>\
                            </span>\
                        </li>'
        );

        $(".js-carousel__main ul").append(newCell);
        newCell.hide().fadeIn(); // Optional: Add fade-in effect
    }

    function switchToNext() {
        var cells = $(".js-carousel__main ul li");

        // Set the z-index of the previous cell to ensure it is behind
        cells.css("z-index", 98);

        cells.removeClass("is-current");

        // Add "is-current" class to the next cell and show it
        cells.eq(currentIndex).addClass("is-current").css("z-index", 99).show();

        currentIndex = (currentIndex + 1) % cells.length;
    }

    function startCarousel() {
        // Call switchToNext immediately after the page is loaded
        switchToNext();

        // Use setInterval to repeat switchToNext with the specified interval
        setInterval(switchToNext, intervalTime);
    }

    // Example: Add new carousel cells with images
    if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i)
    ) {
        addCarouselCell("/images/slideshow01_rwd.jpg");
        addCarouselCell("/images/slideshow02_rwd.jpg");
        addCarouselCell("/images/slideshow03_rwd.jpg");
    } else {
        addCarouselCell("/images/slideshow01.jpg");
        addCarouselCell("/images/slideshow02.jpg");
        addCarouselCell("/images/slideshow03.jpg");
    }

    startCarousel();

    setInterval(switchToNext, intervalTime);

    $(".social-list").on("click", function () {
        var url = $(this).attr("data");
        if ($(this).attr("id") === "youtube") {
            window.open(url, "_blank");
        }
        if ($(this).attr("id") === "instagram") {
            window.open(url, "_blank");
        }
        if ($(this).attr("id") === "facebook") {
            window.open(url, "_blank");
        }
        if ($(this).attr("id") === "line") {
            window.open(url);
        }
    });

    const select = (el, all = false) => {
        el = el.trim();
        if (all) {
            return [...document.querySelectorAll(el)];
        } else {
            return document.querySelector(el);
        }
    };
    /**
     * Mobile nav toggle
     */
    $(".mobile-nav-toggle").on("click", function (e) {
        this.classList.toggle("is-open-sitemap");
        var navbarMobile = $(".navbar-mobile");
        navbarMobile.css("display", (i, value) =>
            value === "none" ? "block" : "none"
        );
    });

    $(".submenu-toggle").on("click", function (e) {
        // select("#navbar").classList.toggle("navbar-mobile");
        var submenu = $(".navbar-mobile .dropdown ul");
        submenu.css("display", (i, value) =>
            value === "none" ? "block" : "none"
        );
        this.classList.toggle("bi-chevron-down");
        this.classList.toggle("bi-chevron-up");
    });

    /**
     * Mobile nav dropdowns activate
     */
    $(".navbar .dropdown > a").on("click", function (e) {
        if (select("#navbar").classList.contains("navbar-mobile")) {
            e.preventDefault();
            this.nextElementSibling.classList.toggle("dropdown-active");
            // if ($(".dropdown_toggle").hasClass("bi-caret-down-fill")) {
            //     $(this).removeClass("bi-caret-down-fill");
            //     $(this).addClass("bi-caret-up-fill");
            // } else {
            //     $(this).addClass("bi-caret-down-fill");
            //     $(this).removeClass("bi-caret-up-fill");
            // }
        }
    });

    $(".submit").on("click", function () {
        return false;
    });

    $("#msform").on("submit", function (e) {
        e.preventDefault();
        let name = $("#name").val();
        let phone = $("#phone").val();
        let spaceType = $("#spaceType").val();
        let location = $("#location").val();
        let houseType = $("#houseType").val();
        let size = $("#size").val();
        let roomsType = $("#roomsType").val();
        let closingDate = $("#closingDate").val();
        let movingDate = $("#movingDate").val();
        let designType = $("#designType").val();
        // let budget = $("#budget").val();
        let schedule = $("#schedule").val();

        $.ajax({
            url: "/submit-form",
            type: "POST",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                name: name,
                phone: phone,
                spaceType: spaceType,
                location: location,
                houseType: houseType,
                size: size,
                roomsType: roomsType,
                closingDate: closingDate,
                movingDate: movingDate,
                designType: designType,
                // budget: budget,
                schedule: schedule,
            },
            success: function (response) {
                $("#successMsg").show();
                console.log(response);
            },
            error: function (response) {
                $("#nameErrorMsg").text(response.responseJSON.errors.name);
                $("#emailErrorMsg").text(response.responseJSON.errors.email);
                $("#mobileErrorMsg").text(response.responseJSON.errors.mobile);
                $("#messageErrorMsg").text(
                    response.responseJSON.errors.message
                );
            },
        });
    });

    $("#contactForm").on("submit", function (e) {
        e.preventDefault();
        let name = $('input[name="name"]').val();
        let email = $('input[name="email"]').val();
        let phone = $('input[name="phone"]').val();
        let addr = $('input[name="addr"]').val();
        let consult = $('input[name="consult"]').val();
        let condition = $('select[name="condition"]').val();
        let budget = $('select[name="budget"]').val();
        let line = $('input[name="line"]').val();

        $('#contactForm').find(":input").attr("disabled", "disabled");
        // $(".contact-us").addClass("loading");
        $(".overlay-form").show();

        var scheduleString = "";
        $('input[name="schedule"]:checked').each(function (index) {
            if (index !== 0) {
                scheduleString += ", "; // Add comma and space if it's not the first element
            }
            scheduleString += $(this).val(); // Add the value of checked checkbox
        });

        let message = $('textarea[name="message"]').val();

        $.ajax({
            url: "/submit-form",
            type: "POST",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                name: name,
                phone: phone,
                email: email,
                addr: addr,
                consult: consult,
                condition: condition,
                budget: budget,
                line: line,
                schedule: scheduleString,
                message: message,
            },
            success: function (response) {
                $(".overlay-form").hide();
                // $(".contact-us").addClass("hide-loading");
                // // For failed icon just replace ".done" with ".failed"
                // $(".done").addClass("finish");
                // setTimeout(function () {
                //     $(".contact-us").removeClass("loading");
                //     $(".contact-us").removeClass("hide-loading");
                //     $(".done").removeClass("finish");
                //     $(".failed").removeClass("finish");
                // }, 5000);


                $("#contactForm").fadeTo("slow", 1, function () {
                    $(this).find(":input").attr("disabled", "disabled");
                    $(this).find("label").css("cursor", "default");
                    // $("#success").fadeIn();
                    // $(".modal").modal("hide");
                    // $("#success").modal("show");
                    $("#contactForm").trigger("reset");
                    alert("感謝您的耐心填寫，在設計將有專人與您聯繫。");
                });
            },
            error: function (response) {
                $(".overlay-form").hide();
                // $(".contact-us").removeClass("loading");
                $('#contactForm :input').prop('disabled', false);
                $("#contactForm").trigger("reset");
                alert("填寫表單發生錯誤。");
            },
        });
    });

    $("#mosaic").Mosaic({
        maxRowHeight: 650,
        refitOnResize: true,
        refitOnResizeDelay: true,
        defaultAspectRatio: 1,
        maxRowHeightPolicy: "crop",
        responsiveWidthThreshold: 500,
        innerGap: 10,
    });

    $(".overlay").on("click", function () {
        var target = $(this).closest("div").find("a");
        var url = target.attr("href");
        window.location.href = url;
    });

    $("select").each(function () {
        // Cache the number of options
        var $this = $(this),
            numberOfOptions = $(this).children("option").length;

        // Hides the select element
        $this.addClass("s-hidden");

        // Wrap the select element in a div
        $this.wrap('<div class="select form-control"></div>');

        // Insert a styled div to sit over the top of the hidden select element
        $this.after('<div class="styledSelect"></div>');

        // Cache the styled div
        var $styledSelect = $this.next("div.styledSelect");

        // Show the first select option in the styled div
        $styledSelect.text($this.children("option").eq(0).text());

        // Insert an unordered list after the styled div and also cache the list
        var $list = $("<ul />", {
            class: "options",
        }).insertAfter($styledSelect);

        // Insert a list item into the unordered list for each select option
        for (var i = 1; i < numberOfOptions; i++) {
            $("<li />", {
                text: $this.children("option").eq(i).text(),
                rel: $this.children("option").eq(i).val(),
            }).appendTo($list);
        }

        // Cache the list items
        var $listItems = $list.children("li");

        // Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
        $styledSelect.on("click", function (e) {
            e.stopPropagation();
            $("div.styledSelect.active").each(function () {
                $(this).removeClass("active").next("ul.options").hide();
            });
            $(this).toggleClass("active").next("ul.options").toggle();
        });

        // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
        // Updates the select element to have the value of the equivalent option
        $listItems.on("click", function (e) {
            e.stopPropagation();
            $styledSelect.removeClass("active");
            $this.val($(this).attr("rel"));
            $list.hide();
            /* alert($this.val()); Uncomment this for demonstration! */
        });

        // Hides the unordered list when clicking outside of it
        $(document).on("click", function (e) {
            $styledSelect.removeClass("active");
            $list.hide();
        });
    });

    // $(".portfolio-col img").each(function () {
    //     var imgWidth = $(this).width();
    //     $(this).parent().find(".overlay").width(imgWidth);
    // });

    $("#home-contact").on("click", function () {
        location.href = "/contact";
    });
});
